import React from 'react';
import styled from 'styled-components';
import { cardNumberFormat, validateCardNumber } from '../../utils/creditCard';

export const InputFormCCNumber = styled.input`
  padding: 15px 15px 10px 40px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 16px;
  color: #001c72;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  background-image: url(/images/card-vector.svg);
  background-repeat: no-repeat;
  background-position: 15px 15px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  &.error {
    border-color: #bc2e3e;
    outline-color: #bc2e3e;
  }
  &::placeholder {
    color: #33498e;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ValidationError = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

export default class InputCCNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: this.props.showError,
      isEditing: false,
      value: '',
      validated: false,
      error_message: 'This value is required.'
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
  }

  validateInput(value) {
    let valid = true;
    if (value.length === 0) {
      this.setState({
        showError: true,
        validated: false,
        error_message: 'This value is required.'
      });
      valid = false;
      return;
    }
    if (value.length < 19) {
      this.setState({
        showError: true,
        validated: false,
        error_message: 'Please enter a valid card number.'
      });
      valid = false;
      return;
    }
    if (!validateCardNumber(value)) {
      this.setState({
        showError: true,
        validated: false,
        error_message: 'Please enter a valid card number.'
      });
      valid = false;
      return;
    }
    if (valid) {
      this.setState({
        showError: false
      });
    }
  }

  handleChange(event) {
    let targetValue = event.target.value;
    const re = /^[0-9\b][\d ]+$/;
    if (!re.test(targetValue)) {
      targetValue = targetValue.replace(/\D/g, '');
    }
    if (targetValue.length >= 4 && targetValue.length % 4 === 0) {
      targetValue = `${targetValue} `;
    }
    this.setState({ value: cardNumberFormat(targetValue) });
    this.props.onChange(cardNumberFormat(targetValue));
    this.validateInput(cardNumberFormat(targetValue));
  }

  toggleEditing(evt) {
    this.handleChange(evt);
    this.setState({ isEditing: evt.type === 'focus' });
  }

  render() {
    const placeholder = this.state.isEditing ? ' ' : '1234 1234 1234 1234';
    return (
      <>
        <InputFormCCNumber
          type="text"
          name={this.props.name || `cardnumber`}
          value={this.state.value}
          onChange={this.handleChange}
          onFocus={() => this.setState({ isEditing: true })}
          onBlur={this.toggleEditing}
          placeholder={placeholder}
          className={this.state.showError ? `error` : ``}
          pattern="[0-9]*"
          autocomplete="cc-number"
        />

        {this.state.showError && (
          <ValidationError>{this.state.error_message}</ValidationError>
        )}
      </>
    );
  }
}
