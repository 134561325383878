import React, { useContext, useEffect, useState } from 'react';
import * as S from './UserPaymentMethodV2.styles';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import useAuth from '../../hooks/useAuth';
import { CSSTransition } from 'react-transition-group';
import { getUserDetails, updatePaypalPayment } from '../../utils/user';
import { addDataLayer } from '../../utils/dataLayer';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import { isTokenExpired } from '../../utils/isTokenExpired';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeElements from './StripeElements/StripeElements';
import CardInputElements from './CardInputElements';

const UserPaymentMethod = props => {
  const {
    isCheckoutComSandbox = false,
    expiredTokenRedirect = 'https://thepetlabco.com/pages/token-expired',
    viewEvent = 'magic_link_payment_method_update_page_view',
    updateEvent = 'magic_link_payment_method_update_selection',
    failEvent = 'magic_link_payment_method_update_failed',
    successEvent = 'magic_link_payment_method_update_successful'
  } = props;

  const stripePromise = loadStripe(
    isCheckoutComSandbox
      ? process.env.GATSBY_STRIPE_API_PUBLIC_KEY_SANDBOX
      : process.env.GATSBY_STRIPE_API_PUBLIC_KEY
  );

  const [ccButtonClicked, setCcButtonClicked] = useState(false);
  const [paypalButtonClicked, setPaypalButtonClicked] = useState(false);
  const [showCardBox, setShowCardBox] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userLoaded, setUserLoaded] = useState(false);

  const { funnelUrl } = useContext(FunnelContext);

  const petlabCampaignTokenKey = 'petlab_campaign_token',
    klaviyoUserIdKey = 'utm_id',
    klaviyoCampaignIdKey = 'utm_campaign',
    redirectIfAuthFails = true,
    useStagingApiEndpoint = false;

  const { token: authToken, loading: loadingCover } = useAuth(
    petlabCampaignTokenKey,
    klaviyoUserIdKey,
    klaviyoCampaignIdKey,
    redirectIfAuthFails,
    expiredTokenRedirect,
    useStagingApiEndpoint
  );

  useEffect(() => {
    if (authToken && isTokenExpired(authToken)) {
      addDataLayer('magic_link_login_failed_event');
      window.location.href = expiredTokenRedirect;
      return;
    }
    if (loadingCover && authToken) {
      getUserDetails({
        bearerToken: authToken
      })
        .then(results => {
          setUserInfo(results);
          setUserLoaded(true);
          addDataLayer(viewEvent, {
            sub_db_user_id: results.user.id
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [viewEvent, loadingCover, authToken, expiredTokenRedirect, setUserLoaded]);

  const handlePaypalButton = () => {
    if (Builder.isEditing) return;
    if (paypalButtonClicked) return;
    if (ccButtonClicked) return;
    setPaypalButtonClicked(true);
    setShowCardBox(false);

    addDataLayer(updateEvent, {
      payment_method: 'Paypal',
      sub_db_user_id: userInfo.user.id
    });

    updatePaypalPayment({
      bearerToken: authToken,
      success_return_url: `${funnelUrl}&layer=success`,
      failed_return_url: `${funnelUrl}&layer=failed`
    })
      .then(({ data: { data } }) => {
        setPaypalButtonClicked(false);
        window.location.href = data.return_url;
      })
      .catch(err => {
        console.log(err);
        setPaypalButtonClicked(false);
      });
  };

  const handleCardButton = () => {
    if (paypalButtonClicked) return;
    if (ccButtonClicked) return;

    if (!showCardBox) {
      addDataLayer(updateEvent, {
        payment_method: 'Credit Card',
        sub_db_user_id: userInfo.user.id
      });
    }

    setShowCardBox(!showCardBox);
  };

  const duration = 5000,
    onEnter = node => {
      node.style.marginTop = `-${node.offsetHeight}px`;
      node.style.marginBottom = `0px`;
    },
    onEntering = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExit = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExiting = node => {
      node.style.marginTop = `-${node.offsetHeight}px`;
      node.style.marginBottom = `0px`;
    };

  if (!userLoaded)
    return (
      <S.Loading>
        <img src="/images/three-dots.svg" alt="loading" />
      </S.Loading>
    );

  return (
    <>
      <S.Container>
        <S.PaypalButton
          onClick={handlePaypalButton}
          className={paypalButtonClicked || ccButtonClicked ? `disabled` : ``}
        >
          {paypalButtonClicked ? (
            <img src="/images/three-dots.svg" alt="loading" />
          ) : (
            <img src="/images/paypal-logo.png" alt="paypal logo" />
          )}
        </S.PaypalButton>
        <S.OrText>
          <span>OR</span>
        </S.OrText>
        <S.CardButton
          onClick={handleCardButton}
          className={paypalButtonClicked || ccButtonClicked ? `disabled` : ``}
        >
          Credit / Debit Card
        </S.CardButton>
        <CSSTransition
          in={showCardBox}
          timeout={duration}
          classNames="slide"
          unmountOnExit
          onEnter={onEnter}
          onEntering={onEntering}
          onExit={onExit}
          onExiting={onExiting}
        >
          <S.CardBoxContainer>
            <S.Body>
              {['US', 'CA'].includes(userInfo.user.storeGeo) && (
                <Elements stripe={stripePromise}>
                  <StripeElements
                    userInfo={userInfo}
                    authToken={authToken}
                    failEvent={failEvent}
                    successEvent={successEvent}
                  />
                </Elements>
              )}
              {['GB', 'UK'].includes(userInfo.user.storeGeo) && (
                <CardInputElements
                  isCheckoutComSandbox={isCheckoutComSandbox}
                  failEvent={failEvent}
                  successEvent={successEvent}
                  paypalButtonClicked={paypalButtonClicked}
                  userInfo={userInfo}
                  authToken={authToken}
                  setCcButtonClicked={setCcButtonClicked}
                  ccButtonClicked={ccButtonClicked}
                />
              )}
            </S.Body>
          </S.CardBoxContainer>
        </CSSTransition>
      </S.Container>
      {(loadingCover || !userLoaded) && !Builder.isEditing && (
        <S.LoadingCover>
          <S.LottieAnimation>
            <LottieAnimation animationFile="https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2F302066eb88bc4ee5a0f6bd4d894e32aa?alt=media&token=9068a8aa-9cea-4a77-b69c-f623c17720c0" />
          </S.LottieAnimation>
        </S.LoadingCover>
      )}
    </>
  );
};

export default UserPaymentMethod;
