import React, { useContext, useEffect, useState } from 'react';
import * as S from './UserPaymentMethodV3.styles';
import useAuth from '../../hooks/useAuth';
import { getUserDetails, updatePaypalPayment } from '../../utils/user';
import { addDataLayer } from '../../utils/dataLayer';
import FunnelContext from '../../context/FunnelContext';
import { Builder, Image } from '@builder.io/react';
import { isTokenExpired } from '../../utils/isTokenExpired';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeElementsNewStyle from './StripeElements/StripeElementsNewStyle';
import CardInputElementsNewStyle from './CardInputElementsNewStyle';

const UserPaymentMethod = props => {
  const {
    isCheckoutComSandbox = false,
    expiredTokenRedirect = 'https://thepetlabco.com/pages/token-expired',
    viewEvent = 'magic_link_payment_method_update_page_view',
    updateEvent = 'magic_link_payment_method_update_selection',
    failEvent = 'magic_link_payment_method_update_failed',
    successEvent = 'magic_link_payment_method_update_successful',
    children
  } = props;

  const [ccButtonClicked, setCcButtonClicked] = useState(false);
  const [paypalButtonClicked, setPaypalButtonClicked] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userLoaded, setUserLoaded] = useState(false);
  const [isTestAccount, setIsTestAccount] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const { funnelUrl } = useContext(FunnelContext);

  const petlabCampaignTokenKey = 'petlab_campaign_token',
    klaviyoUserIdKey = 'utm_id',
    klaviyoCampaignIdKey = 'utm_campaign',
    redirectIfAuthFails = true,
    useStagingApiEndpoint = false;

  const { token: authToken, loading: loadingCover } = useAuth(
    petlabCampaignTokenKey,
    klaviyoUserIdKey,
    klaviyoCampaignIdKey,
    redirectIfAuthFails,
    expiredTokenRedirect,
    useStagingApiEndpoint
  );

  useEffect(() => {
    if (authToken && isTokenExpired(authToken)) {
      addDataLayer('magic_link_login_failed_event');
      window.location.href = expiredTokenRedirect;
      return;
    }

    if (loadingCover && authToken) {
      getUserDetails({
        bearerToken: authToken
      })
        .then(results => {
          setUserInfo(results);
          const isTestAccount =
            (results.user?.email.includes('test') &&
              results.user?.email.includes('thepetlabco')) ||
            false;
          setIsTestAccount(isTestAccount);
          setStripePromise(
            loadStripe(
              isTestAccount
                ? process.env.GATSBY_STRIPE_API_PUBLIC_KEY_SANDBOX
                : process.env.GATSBY_STRIPE_API_PUBLIC_KEY
            )
          );
          setUserLoaded(true);
          addDataLayer(viewEvent, {
            sub_db_user_id: results.user.id
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [viewEvent, loadingCover, authToken, expiredTokenRedirect, setUserLoaded]);

  const cardName = userInfo.user?.processor;
  const PaymentCardImage = ({ cardName }) => {
    const cardImages = {
      visa: '/images/footer-icons/visa.svg',
      mastercard: '/images/footer-icons/mastercard.svg',
      americanexpress: '/images/footer-icons/amex.svg'
    };

    const processor = cardName?.toLowerCase();

    if (cardImages.hasOwnProperty(processor)) {
      const imageUrl = cardImages[processor];
      return <img src={imageUrl} alt={cardName} />;
    } else {
      return <img src="/images/footer-icons/visa.svg" alt="card" />;
    }
  };

  const handlePaypalButton = () => {
    if (Builder.isEditing) return;
    if (paypalButtonClicked) return;
    if (ccButtonClicked) return;
    setPaypalButtonClicked(true);

    addDataLayer(updateEvent, {
      payment_method: 'Paypal',
      sub_db_user_id: userInfo.user.id
    });

    updatePaypalPayment({
      bearerToken: authToken,
      success_return_url: `${funnelUrl}&layer=success`,
      failed_return_url: `${funnelUrl}&layer=failed`
    })
      .then(({ data: { data } }) => {
        setPaypalButtonClicked(false);
        window.location.href = data.return_url;
      })
      .catch(err => {
        console.log(err);
        setPaypalButtonClicked(false);
      });
  };
  if (!userLoaded)
    return (
      <S.Loading>
        <img src="/images/three-dots.svg" alt="loading" />
      </S.Loading>
    );

  const firstName = userInfo?.shippingAddress?.firstName.trim();

  return (
    <S.Container>
      <div>
        <S.HelloTitle>Hi {firstName}! 👋</S.HelloTitle>
        {children}
      </div>
      <S.ContainerUpdateCard>
        <S.FailedCardWrapper>
          {userInfo?.user?.paymentMethod === 'card' ? (
            <S.FailedCard>
              <PaymentCardImage cardName={cardName} />
              <p>****{userInfo?.user?.last4Digits}</p>
              <p>
                {userInfo?.user?.expiryMonth}/{userInfo?.user?.expiryYear % 100}
              </p>
            </S.FailedCard>
          ) : null}
          <S.FailedText>
            <img src="/images/warning.svg" alt="warning " />
            <p>Payment failed</p>
          </S.FailedText>
        </S.FailedCardWrapper>
        <S.ContainerCard>
          <S.CardTitle>Credit/Debit Card</S.CardTitle>
          <S.CardBoxContainer>
            <S.Body>
              {['US', 'CA'].includes(userInfo.user.storeGeo) && (
                <Elements stripe={stripePromise}>
                  <StripeElementsNewStyle
                    userInfo={userInfo}
                    authToken={authToken}
                    failEvent={failEvent}
                    successEvent={successEvent}
                  />
                </Elements>
              )}
              {['GB', 'UK'].includes(userInfo.user.storeGeo) && (
                <CardInputElementsNewStyle
                  isCheckoutComSandbox={isCheckoutComSandbox}
                  failEvent={failEvent}
                  successEvent={successEvent}
                  paypalButtonClicked={paypalButtonClicked}
                  userInfo={userInfo}
                  authToken={authToken}
                  setCcButtonClicked={setCcButtonClicked}
                  ccButtonClicked={ccButtonClicked}
                />
              )}
              <S.PaymentProviders>
                <ul>
                  <li>
                    <Image src="/images/footer-icons/amex.svg" alt="amex " />
                  </li>
                  <li>
                    <Image
                      src="/images/footer-icons/discover-network.svg"
                      alt="discover network image"
                    />
                  </li>
                  <li>
                    <Image
                      src="/images/footer-icons/mastercard.svg"
                      alt="mastercard "
                    />
                  </li>
                  <li className={'first-align'}>
                    <Image src="/images/footer-icons/visa.svg" alt="visa" />
                  </li>
                </ul>
              </S.PaymentProviders>
              <S.Secure>
                <Image src="/images/security.svg" alt="security " />
                <p>All transactions are secure and encrypted.</p>
              </S.Secure>
            </S.Body>
            <S.OrText>
              <span>OR</span>
              <S.PaypalButton
                onClick={handlePaypalButton}
                className={
                  paypalButtonClicked || ccButtonClicked ? `disabled` : ``
                }
              >
                {paypalButtonClicked ? (
                  <img src="/images/three-dots.svg" alt="loading" />
                ) : (
                  <img src="/images/paypal-logo.svg" alt="paypal logo" />
                )}
              </S.PaypalButton>
            </S.OrText>
          </S.CardBoxContainer>
        </S.ContainerCard>
        {(loadingCover || !userLoaded) && !Builder.isEditing && (
          <S.LoadingCover>
            <S.LottieAnimation>
              <LottieAnimation animationFile="https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2F302066eb88bc4ee5a0f6bd4d894e32aa?alt=media&token=9068a8aa-9cea-4a77-b69c-f623c17720c0" />
            </S.LottieAnimation>
          </S.LoadingCover>
        )}
      </S.ContainerUpdateCard>
    </S.Container>
  );
};

export default UserPaymentMethod;
