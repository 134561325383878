import React, { useState, useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './UserPaymentMethodV1.styles';
import { requestPaymentToken } from '../../utils/requestPaymentToken';
import { updatePaymentMethod } from '../../utils/user';
import { validateCardNumber, cleanCardNumber } from '../../utils/creditCard';
import { navigate } from 'gatsby';
import InputCCNumber from './InputCCNumber';
import InputCCExpiry from './InputCCExpiry';
import InputCVV from './InputCVV';

const UserPaymentMethod = props => {
  const {
    designVersion,
    updateToApi,
    endpointApiUrl,
    isCheckoutComSandbox,
    confirmationPage
  } = props;

  const { extraObjects } = useContext(FunnelContext);

  const { tokenQuery, user } = extraObjects;
  const { address, storeGeo } = user ?? { address: {}, storeGeo: '' };

  const [paymentType] = useState('card');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [ccDetails, setCcDetails] = useState({
    ccn: '',
    exp: '',
    cvv: ''
  });
  const [validated, setValidated] = useState({
    ccn: false,
    exp: false,
    cvv: false
  });
  const [showError, setShowError] = useState({
    ccn: false,
    exp: false,
    cvv: false
  });

  const ExpiryNotExpired = () => {
    const expMonth = ccDetails.exp.split('/')[0];
    const expYear = ccDetails.exp.split('/')[1];

    const today = new Date();
    const expiryDate = new Date(`${expMonth}/1/${expYear}`);

    return expiryDate > today;
  };

  const validateCCDetail = props => {
    setValidated(
      Object.assign(validated, {
        ccn: validateCardNumber(props.ccn),
        exp:
          props.exp.length === 5 &&
          props.exp.indexOf('/') &&
          ExpiryNotExpired(),
        cvv: props.cvv.length === 3
      })
    );

    if (validated.ccn && validated.exp && validated.cvv) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const triggerChangeNumber = value => {
    setCcDetails(
      Object.assign(ccDetails, {
        ccn: cleanCardNumber(value)
      })
    );
    validateCCDetail({
      ...ccDetails,
      ccn: cleanCardNumber(value)
    });
    setShowError({
      ...showError,
      ccn: true
    });
  };

  const triggerChangeExpiry = value => {
    setCcDetails(
      Object.assign(ccDetails, {
        exp: value
      })
    );
    validateCCDetail({
      ...ccDetails,
      exp: value
    });
    setShowError({
      ...showError,
      exp: true
    });
  };

  const triggerChangeCvv = value => {
    setCcDetails(
      Object.assign(ccDetails, {
        cvv: value
      })
    );
    validateCCDetail({
      ...ccDetails,
      cvv: value
    });
    setShowError({
      ...showError,
      cvv: true
    });
  };

  const triggerUpdate = () => {
    if (!buttonClicked && !buttonDisabled) {
      setButtonClicked(true);

      if (updateToApi) {
        requestPaymentToken({
          sandbox: isCheckoutComSandbox ?? true,
          cc_number: ccDetails.ccn,
          expiry_month: ccDetails.exp.split('/')[0],
          expiry_year: ccDetails.exp.split('/')[1],
          cvv: ccDetails.cvv,
          type: paymentType,
          storeGeo
        })
          .then(result => {
            if (result) {
              const checkoutToken = result.data;
              const finalShippingAddress = address?.billing_as_shipping
                ? address?.billing
                : address?.shipping;

              updatePaymentMethod({
                headerToken: tokenQuery,
                checkoutToken: checkoutToken.token,
                paymentGateway: checkoutToken.gateway,
                apiUrl: endpointApiUrl,
                billing: address?.billing,
                shipping: finalShippingAddress,
                billingAsShipping: address?.billing_as_shipping
              }).then(() => {
                setButtonClicked(false);
                navigate(confirmationPage, {
                  replace: true
                });
              });
            } else {
              setButtonClicked(false);
            }
          })
          .catch(() => {
            setButtonClicked(false);
          });
      } else {
        setButtonClicked(false);
      }
    }
  };

  return (
    <S.Container designVersion={designVersion}>
      <S.OuterBorder>
        <S.Header>
          <S.OptionRadio className="active"></S.OptionRadio>
          <S.OptionLabel>Credit/Debit Card</S.OptionLabel>
        </S.Header>
        <S.Body>
          <S.InputContainer className="mt-0">
            <S.InputLabel>Card Number</S.InputLabel>

            <InputCCNumber
              value={ccDetails.ccn}
              showError={showError.ccn && !validated.ccn}
              onChange={triggerChangeNumber}
            />
          </S.InputContainer>

          <S.Row>
            <S.ColOne>
              <S.InputContainer>
                <S.InputLabel>Expiration Date</S.InputLabel>
                <InputCCExpiry
                  value={ccDetails.exp}
                  showError={showError.exp && !validated.exp}
                  onChange={triggerChangeExpiry}
                />
              </S.InputContainer>
            </S.ColOne>
            <S.ColTwo>
              <S.InputContainer>
                <S.InputLabel>CVV Number</S.InputLabel>

                <InputCVV
                  value={ccDetails.cvv}
                  showError={showError.cvv && !validated.cvv}
                  onChange={triggerChangeCvv}
                />
              </S.InputContainer>
            </S.ColTwo>
          </S.Row>

          <S.UpdateButton
            buttonDisabled={buttonDisabled}
            buttonClicked={buttonClicked}
            onClick={triggerUpdate}
          >
            <span>UPDATE PAYMENT METHOD</span>
          </S.UpdateButton>

          <S.BottomTextContainer>
            <S.BottomText>
              All transactions are secure and encrypted.
            </S.BottomText>
            <S.BottomLogos></S.BottomLogos>
          </S.BottomTextContainer>
        </S.Body>
      </S.OuterBorder>
    </S.Container>
  );
};

export default UserPaymentMethod;
