import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

export const HelloTitle = styled.div`
  margin: 70px 0 16px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 44.8px;
  @media (max-width: 990px) {
    margin: 30px 0 16px 0;
    text-align: center;
  }
  @media (max-width: 640px) {
    margin: 0;
    text-align: left;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ContainerUpdateCard = styled.div`
  max-width: 450px;
  margin-left: 20%;
  @media (max-width: 1000px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
  }
`;

export const FailedCardWrapper = styled.div`
  display: flex;
  background: #f8eaec;
  border: 1px solid #f2d5d8;
  border-radius: 8px;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const FailedCard = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0 0 0 8px;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const FailedText = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0 0 0 4px;
    padding-top: 2px;
    font-size: 16px;
    font-weight: 700;
    color: #e41111;
  }
  @media (max-width: 600px) {
    p {
      margin: 0 0 0 2px;
    }
  }
`;

export const ContainerCard = styled.div`
  margin-top: 11px;
  background-color: #ffffff;
  padding: 32px;
  border-radius: 8px;
  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: #001c72;
`;

export const CardButton = styled.div`
  background: #001c72;
  border-radius: 4px;
  padding: 18px 10px 16px;
  cursor: pointer;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 4px;
  &:hover {
    background: #01175a;
  }
  &.disabled {
    background: #01175a;
    cursor: initial;
  }
  z-index: 99;
  position: relative;
`;

export const CardBoxContainer = styled.div`
  margin-top: 24px;
  padding-inline: 32px;
  z-index: 90;
  position: relative;
  @media (max-width: 600px) {
    padding-inline: 0;
  }
`;

export const LoadingCover = styled.div`
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
`;

export const LottieAnimation = styled.div`
  width: 300px;
  display: flex;
  margin: 0 auto;
  height: 300px;
  margin-top: calc((100vh - 300px) / 2);
`;

export const Body = styled.div`
  min-height: 100px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ColOne = styled.div`
  width: 50%;
  padding-right: 4px;
`;

export const ColTwo = styled.div`
  width: 50%;
  padding-left: 4px;
`;

export const InputContainer = styled.div`
  margin-top: 20px;
  position: relative;
  &.mt-0 {
    margin-top: 0;
  }
`;
export const InputLabel = styled.div`
  color: #33498e;
  font-family: 'Neuzeit Grotesk', sans-serif;
  padding-inline: 2px;
  font-size: 14px;
  margin-bottom: 5px;
  position: absolute;
  top: -7px;
  left: 10px;
  font-weight: 700;
  background-color: #ffffff;
`;

export const UpdateButton = styled.button`
  border-radius: 40px;
  background-color: #bc2e3e;
  text-align: center;
  font-size: 20px;
  padding: 12px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  border: none;
  outline: none;
  color: #fff;
  width: 100%;
  font-weight: 700;
  line-height: 28px;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    background-color: #a02734;
  }

  ${({ buttonClicked }) => {
    return buttonClicked
      ? `
    &:before {
      content: "";
      height: 22px;
      width: 100%;
      background-image: url("/images/three-dots.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    & > span {
      display: none;
    }
   
    background-color: #a02734;
    cursor: initial;
      
    `
      : ``;
  }}

  ${({ buttonDisabled }) => {
    return buttonDisabled
      ? `
    opacity: 0.5;
    cursor:initial;
    &:hover {
      background-color:#BC2E3E;
    }
    `
      : ``;
  }}
`;

export const PaymentProviders = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 1;

  ul {
    display: flex;
    margin-block: 16px;
    align-items: center;
    padding: 0;
    justify-content: center;

    li {
      display: inline-block;
      list-style-type: none;
      align-items: center;
      justify-content: center;
      height: 25px;
      margin-bottom: 0 !important;
      margin-left: 8px;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 12px;
  }
`;

export const Secure = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0 24px 0;
  p {
    margin: 0 0 0 3px;
    font-size: 14px;
    justify-content: center;
  }
`;

export const PaypalButton = styled.div`
  background: #ffcc00;
  border-radius: 52px;
  max-width: 100%;
  padding: 15px 10px 12px;
  align-items: center;
  display: flex;
  cursor: pointer;
  margin-top: 16px;
  &:hover {
    background: #eabb01;
  }
  & img {
    height: 25px;
    display: flex;
    margin: auto;
  }
  &.disabled {
    background: #eabb01;
    cursor: initial;
  }
  z-index: 99;
  position: relative;
`;

export const OrText = styled.div`
  font-family: 'Neuzeit Grotesk', sans-serif;
  text-align: center;
  position: relative;
  z-index: 99;
  position: relative;
  background: #fff;
  padding: 10px 0;
  & > span {
    width: 50px;
    background: #fff;
    display: block;
    color: #33498e;
    font-weight: 700;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }
  &:before {
    content: ' ';
    border-bottom: 1px solid #d9d9d9;
    display: block;
    margin-top: 8px;
    width: 100%;
    position: absolute;
    z-index: 1;
  }
`;

export const Loading = styled.div`
  display: flex;
  padding: 50px;
  background: #f2f4f8;
  border-radius: 4px;
  justify-content: center;
`;
