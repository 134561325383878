import React from 'react';
import UserPaymentMethodV1 from './UserPaymentMethodV1';
import UserPaymentMethodV2 from './UserPaymentMethodV2';
import UserPaymentMethodV2Direct from './UserPaymentMethodV2Direct';
import UserPaymentMethodV3 from './UserPaymentMethodV3';

const UserPaymentMethod = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2_direct':
      return <UserPaymentMethodV2Direct {...props} />;
    case 'version2':
      return <UserPaymentMethodV2 {...props} />;
    case 'version3':
      return <UserPaymentMethodV3 {...props} />;
    default:
    case 'version1':
      return <UserPaymentMethodV1 {...props} />;
  }
};

export default UserPaymentMethod;
