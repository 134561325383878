import React, { useContext, useState } from 'react';
import * as S from './UserPaymentMethodV3.styles';
import InputCCNumber from './InputCCNumber';
import InputCCExpiry from './InputCCExpiry';
import InputCVV from './InputCVV';
import { cleanCardNumber, validateCardNumber } from '../../utils/creditCard';
import { requestCKOPaymentToken } from '../../utils/requestPaymentToken';
import { updatePaymentMethod2 } from '../../utils/user';
import utmTracking from '../../utils/utmTracking';
import { getParamFromQuery } from '../../utils/getParamFromQuery';
import { Builder } from '@builder.io/react';
import { addDataLayer } from '../../utils/dataLayer';
import FunnelContext from '../../context/FunnelContext';

const CardInputElements = props => {
  const {
    paypalButtonClicked,
    isCheckoutComSandbox,
    successEvent,
    failEvent,
    userInfo,
    authToken,
    setCcButtonClicked,
    ccButtonClicked
  } = props;

  const [ccButtonDisabled, setCcButtonDisabled] = useState(true);
  const { setCurrentLayer, extraObjects, setExtraObjects } = useContext(
    FunnelContext
  );

  const [ccDetails, setCcDetails] = useState({
    ccn: '',
    exp: '',
    cvv: ''
  });
  const [validated, setValidated] = useState({
    ccn: false,
    exp: false,
    cvv: false
  });
  const [showError, setShowError] = useState({
    ccn: false,
    exp: false,
    cvv: false
  });

  const ExpiryNotExpired = () => {
    const expMonth = ccDetails.exp.split('/')[0];
    const expYear = ccDetails.exp.split('/')[1];

    const today = new Date();
    const expiryDate = new Date(`${expMonth}/1/${expYear}`);

    return expiryDate > today;
  };

  const validateCCDetail = props => {
    setValidated(
      Object.assign(validated, {
        ccn: validateCardNumber(props.ccn),
        exp:
          props.exp.length === 5 &&
          props.exp.indexOf('/') &&
          ExpiryNotExpired(),
        cvv: props.cvv.length === 3
      })
    );

    if (validated.ccn && validated.exp && validated.cvv) {
      setCcButtonDisabled(false);
    } else {
      setCcButtonDisabled(true);
    }
  };

  const triggerChangeNumber = value => {
    setCcDetails(
      Object.assign(ccDetails, {
        ccn: cleanCardNumber(value)
      })
    );
    validateCCDetail({
      ...ccDetails,
      ccn: cleanCardNumber(value)
    });
    setShowError({
      ...showError,
      ccn: true
    });
  };

  const triggerChangeExpiry = value => {
    setCcDetails(
      Object.assign(ccDetails, {
        exp: value
      })
    );
    validateCCDetail({
      ...ccDetails,
      exp: value
    });
    setShowError({
      ...showError,
      exp: true
    });
  };

  const triggerChangeCvv = value => {
    setCcDetails(
      Object.assign(ccDetails, {
        cvv: value
      })
    );
    validateCCDetail({
      ...ccDetails,
      cvv: value
    });
    setShowError({
      ...showError,
      cvv: true
    });
  };

  const triggerCCUpdate = () => {
    if (Builder.isEditing) return;
    if (ccButtonDisabled) return;
    if (paypalButtonClicked) return;
    if (ccButtonClicked) return;
    setCcButtonClicked(true);

    const _isCheckoutComSandbox =
      getParamFromQuery('sandbox') === '1' || isCheckoutComSandbox;

    setExtraObjects(
      Object.assign(
        extraObjects,
        ccDetails,
        { authToken },
        { _isCheckoutComSandbox },
        { user: userInfo.user }
      )
    );

    requestCKOPaymentToken({
      sandbox: _isCheckoutComSandbox,
      cc_number: ccDetails.ccn,
      expiry_month: ccDetails.exp.split('/')[0],
      expiry_year: ccDetails.exp.split('/')[1],
      cvv: ccDetails.cvv,
      type: 'card',
      storeGeo: userInfo.user.storeGeo
    })
      .then(({ data }) => {
        updatePaymentMethod2({
          bearerToken: authToken,
          checkoutToken: data.token,
          paymentGateway: 'checkout2',
          utmTracking: utmTracking(window.location.pathname)
        })
          .then(() => {
            setCcButtonClicked(false);
            addDataLayer(successEvent, {
              payment_method: 'Credit Card',
              sub_db_user_id: userInfo.user.id
            });
            setCurrentLayer('success');
          })
          .catch(() => {
            setCcButtonClicked(false);
            addDataLayer(failEvent, {
              payment_method: 'Credit Card',
              sub_db_user_id: userInfo.user.id
            });
            setCurrentLayer('failed');
          });
      })
      .catch(() => {
        setCcButtonClicked(false);
      });
  };

  return (
    <>
      <S.InputContainer className="mt-0">
        <S.InputLabel>Card Number</S.InputLabel>
        <InputCCNumber
          value={ccDetails.ccn}
          showError={showError.ccn && !validated.ccn}
          onChange={triggerChangeNumber}
        />
      </S.InputContainer>

      <S.Row>
        <S.ColOne>
          <S.InputContainer>
            <S.InputLabel>Expiration Date</S.InputLabel>
            <InputCCExpiry
              value={ccDetails.exp}
              showError={showError.exp && !validated.exp}
              onChange={triggerChangeExpiry}
            />
          </S.InputContainer>
        </S.ColOne>
        <S.ColTwo>
          <S.InputContainer>
            <S.InputLabel>CVV Number</S.InputLabel>

            <InputCVV
              value={ccDetails.cvv}
              showError={showError.cvv && !validated.cvv}
              onChange={triggerChangeCvv}
            />
          </S.InputContainer>
        </S.ColTwo>
      </S.Row>
      <S.UpdateButton
        buttonDisabled={ccButtonDisabled}
        buttonClicked={ccButtonClicked}
        onClick={triggerCCUpdate}
      >
        <span>Update payment</span>
      </S.UpdateButton>
    </>
  );
};

export default CardInputElements;
