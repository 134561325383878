import styled from 'styled-components';

export const InputContainer = styled.div`
  margin-top: 20px;
  &.mt-0 {
    margin-top: 0;
  }
`;
export const InputLabel = styled.div`
  color: #001c72;
  font-family: Utopia Std;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ColOne = styled.div`
  width: 50%;
  padding-right: 8px;
`;

export const ColTwo = styled.div`
  width: 50%;
  padding-left: 8px;
`;

export const InputFormCCNumber = styled.div`
  padding: 10px 15px 10px;
  font-family: Utopia Std;
  font-size: 16px;
  color: #001c72;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  padding-left: 50px;
  background-image: url(/images/card-vector.svg);
  background-repeat: no-repeat;
  background-position: 15px 10px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  &.error {
    border-color: #bc2e3e;
    outline-color: #bc2e3e;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const InputFormExpiry = styled.div`
  padding: 10px 15px 10px;
  font-family: Utopia Std;
  font-size: 16px;
  color: #001c72;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  padding-left: 45px;
  background-image: url(/images/cal-vector.svg);
  background-repeat: no-repeat;
  background-position: 15px 10px;
  &.error {
    border-color: #bc2e3e;
    outline-color: #bc2e3e;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const InputFormCVV = styled.div`
  padding: 10px 15px 10px;
  font-family: Utopia Std;
  font-size: 16px;
  color: #001c72;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  padding-left: 50px;
  background-image: url(/images/card-vector.svg);
  background-repeat: no-repeat;
  background-position: 15px 10px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  &.error {
    border-color: #bc2e3e;
    outline-color: #bc2e3e;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const UpdateButton = styled.button`
  border-radius: 4px;
  background-color: #bc2e3e;
  text-align: center;
  font-size: 16px;
  padding: 15px 14px 12px;
  font-family: Neuzeit Grotesk;
  border: none;
  outline: none;
  color: #fff;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  letter-spacing: 2px;
  &:hover {
    background-color: #a02734;
  }

  ${({ buttonClicked }) => {
    return buttonClicked
      ? `
    &:before {
      content: "";
      height: 22px;
      width: 100%;
      background-image: url("/images/three-dots.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    & > span {
      display: none;
    }
   
    background-color: #a02734;
    cursor: initial;
      
    `
      : ``;
  }}

  ${({ buttonDisabled }) => {
    return buttonDisabled
      ? `
    opacity: 0.5;
    cursor:initial;
    &:hover {
      background-color:#BC2E3E;
    }
    `
      : ``;
  }}
`;
