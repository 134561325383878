import styled from 'styled-components';

export const InputContainer = styled.div`
  margin-top: 20px;
  position: relative;
  &.mt-0 {
    margin-top: 0;
  }
`;

export const InputLabel = styled.div`
  color: #33498e;
  font-family: 'Neuzeit Grotesk', sans-serif;
  padding-inline: 2px;
  font-size: 14px;
  margin-bottom: 5px;
  position: absolute;
  top: -7px;
  left: 10px;
  font-weight: 700;
  background-color: #ffffff;
`;

export const InputFormCCNumber = styled.div`
  padding: 15px 15px 10px 40px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 16px;
  color: #001c72;
  width: 310px;
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  background-image: url(/images/card-vector.svg);
  background-repeat: no-repeat;
  background-position: 15px 15px;

  @media (max-width: 380px) {
    width: 280px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ColOne = styled.div`
  width: 50%;
  padding-right: 4px;
`;

export const ColTwo = styled.div`
  width: 50%;
  padding-left: 4px;
`;

export const InputFormExpiry = styled.div`
  padding: 15px 12px 10px 34px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 16px;
  color: #001c72;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  background-image: url(/images/cal-vector.svg);
  background-repeat: no-repeat;
  background-position: 15px 15px;
`;

export const InputFormCVV = styled.div`
  padding: 15px 15px 10px 40px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 16px;
  color: #001c72;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  background-image: url(/images/card-vector.svg);
  background-repeat: no-repeat;
  background-position: 15px 15px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
`;

export const UpdateButton = styled.button`
  border-radius: 40px;
  background-color: #bc2e3e;
  text-align: center;
  font-size: 20px;
  padding: 12px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  border: none;
  outline: none;
  color: #fff;
  width: 100%;
  font-weight: 700;
  line-height: 28px;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    background-color: #a02734;
  }

  ${({ buttonClicked }) => {
    return buttonClicked
      ? `
    &:before {
      content: "";
      height: 22px;
      width: 100%;
      background-image: url("/images/three-dots.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    & > span {
      display: none;
    }
   
    background-color: #a02734;
    cursor: initial;
      
    `
      : ``;
  }}

  ${({ buttonDisabled }) => {
    return buttonDisabled
      ? `
    opacity: 0.5;
    cursor:initial;
    &:hover {
      background-color:#BC2E3E;
    }
    `
      : ``;
  }}
`;
