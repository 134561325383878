import React from 'react';
import styled from 'styled-components';
import { expiryFormat } from '../../utils/creditCard';

export const InputFormExpiry = styled.input`
  padding: 15px 12px 10px 34px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 16px;
  color: #001c72;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  background-image: url(/images/cal-vector.svg);
  background-repeat: no-repeat;
  background-position: 15px 15px;
  &.error {
    border-color: #bc2e3e;
    outline-color: #bc2e3e;
  }
  &::placeholder {
    color: #33498e;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ValidationError = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

export default class InputCCExpiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: this.props.showError,
      isEditing: false,
      value: '',
      validated: false,
      error_message: 'This value is required.'
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
  }

  isExpired(value) {
    const expMonth = value.split('/')[0];
    const expYear = value.split('/')[1];

    const today = new Date();
    const expiryDate = new Date(`${expMonth}/1/${expYear}`);

    return expiryDate < today;
  }

  validateInput(value) {
    if (value.trim().length === 0) {
      this.setState({
        showError: true,
        validated: false,
        error_message: 'This value is required.'
      });
    } else if (this.isExpired(value)) {
      this.setState({
        showError: true,
        validated: false,
        error_message: 'Please enter a valid expiry date.'
      });
    } else if (value.trim().length < 5) {
      this.setState({
        showError: true,
        validated: false,
        error_message: 'Please enter a valid expiry date.'
      });
    } else {
      this.setState({
        showError: false
      });
    }
  }

  handleChange(event) {
    let targetValue = event.target.value;
    if (targetValue.length === 3) {
      targetValue = `${targetValue}/`;
    }
    this.setState({ value: expiryFormat(targetValue) });
    this.props.onChange(expiryFormat(targetValue));
    this.validateInput(expiryFormat(targetValue));
  }

  toggleEditing(evt) {
    this.handleChange(evt);
    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    return (
      <>
        <InputFormExpiry
          type="text"
          maxLength="5"
          name={this.props.name || `cardexp`}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.toggleEditing}
          placeholder="MM/YY"
          className={this.state.showError ? `error` : ``}
          pattern="[0-9]*"
          autocomplete="cc-exp"
        />

        {this.state.showError && (
          <ValidationError>{this.state.error_message}</ValidationError>
        )}
      </>
    );
  }
}
