import React, { useContext, useState } from 'react';
import * as S from './StripeElementsNewStyle.styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { updatePaymentMethod2 } from '../../../utils/user';
import utmTracking from '../../../utils/utmTracking';
import { addDataLayer } from '../../../utils/dataLayer';
import FunnelContext from '../../../context/FunnelContext';

const StripeElements = props => {
  const { authToken, userInfo, failEvent, successEvent } = props;

  const [buttonClicked, setButtonClicked] = useState(false);
  const [validatedNum, setValidatedNum] = useState(false);
  const [validatedExp, setValidatedExp] = useState(false);
  const [validatedCvv, setValidatedCvv] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const { setCurrentLayer } = useContext(FunnelContext);

  if (!stripe || !elements) {
    return null;
  }

  const onClickHandler = async () => {
    try {
      if (validatedNum && validatedExp && validatedCvv) {
        setButtonClicked(true);
      }
      const cardNumber = elements?.getElement(CardNumberElement);
      const result = await stripe.createToken(cardNumber);
      if (result.token) {
        updatePaymentMethod2({
          bearerToken: authToken,
          checkoutToken: result.token.id,
          paymentGateway: 'stripe',
          utmTracking: utmTracking(window.location.pathname)
        })
          .then(() => {
            addDataLayer(successEvent, {
              payment_method: 'Credit Card',
              sub_db_user_id: userInfo.user.id
            });
            setCurrentLayer('success');
          })
          .catch(() => {
            setButtonClicked(false);
            addDataLayer(failEvent, {
              payment_method: 'Credit Card',
              sub_db_user_id: userInfo.user.id
            });
            setCurrentLayer('failed');
          });
      }
    } catch (error) {
      console.log(error);
      setButtonClicked(false);
    }
  };

  const onChangeNumber = e => setValidatedNum(e.complete);
  const onChangeExpiry = e => setValidatedExp(e.complete);
  const onChangeCvv = e => setValidatedCvv(e.complete);

  const inputStyle = {
    fontSize: '16px',
    fontFamily: 'Neuzeit Grotesk, sans-serif',
    color: '#001c72',
    backgroundRepeat: 'no-repeat',
    '::placeholder': {
      color: '#33498e'
    }
  };

  return (
    <>
      <S.InputContainer className="mt-0">
        <S.InputLabel>Card Number</S.InputLabel>
        <S.InputFormCCNumber>
          <CardNumberElement
            onChange={onChangeNumber}
            options={{
              style: {
                base: inputStyle
              }
            }}
          />
        </S.InputFormCCNumber>
      </S.InputContainer>

      <S.Row>
        <S.ColOne>
          <S.InputContainer>
            <S.InputLabel>Expiration Date</S.InputLabel>
            <S.InputFormExpiry>
              <CardExpiryElement
                onChange={onChangeExpiry}
                options={{
                  style: {
                    base: inputStyle
                  }
                }}
              />
            </S.InputFormExpiry>
          </S.InputContainer>
        </S.ColOne>
        <S.ColTwo>
          <S.InputContainer>
            <S.InputLabel>CVV Number</S.InputLabel>
            <S.InputFormCVV>
              <CardCvcElement
                onChange={onChangeCvv}
                options={{
                  style: {
                    base: inputStyle
                  }
                }}
              />
            </S.InputFormCVV>
          </S.InputContainer>
        </S.ColTwo>
      </S.Row>

      <S.UpdateButton
        buttonDisabled={!(validatedNum && validatedExp && validatedCvv)}
        buttonClicked={buttonClicked}
        onClick={onClickHandler}
      >
        <span>Update payment</span>
      </S.UpdateButton>
    </>
  );
};

export default StripeElements;
